import { auth } from '@/http/api/auth.js'
import { dashboard } from '@/http/api/dashboard.js'
import { report } from '@/http/api/report.js'
import i18n from '@/lang/lang.js'
import moment from 'moment-timezone'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false,
    loadingText: null,
    isAlerting: false,
    hasStatistics: false,
    viewport: null,
    windowWidth: 0,
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400
    },
    alerts: {
      state: null,
      icon: null,
      title: null,
      info: null
    },
    token: {
      headers: {
        Authorization: null
      }
    },
    userInfo: null,
    notifyList: null,
    updateCycle: process.env.VUE_APP_API_UPDATE_CYCLE || 1000,
    timezone: 'UTC',
    toDayUTCseconds: Math.round(new Date().getTime() / 1000),
    toDayUTCmilliseconds: new Date().getTime()
  },
  getters: {
    sortNotify: state => {
      var copyNotifications = []
      if (!state.notifyList || !state.notifyList.length) return ''
      // 匯集所有裝置的通知
      state.notifyList.forEach(notify => {
        // 合併陣列
        const array = JSON.parse(JSON.stringify(notify.message))
        copyNotifications = copyNotifications.concat(array)
      })
      // 將時間順序排好
      return copyNotifications.sort((a, b) => {
        return (
          Date.parse(b.created_at).valueOf() -
          Date.parse(a.created_at).valueOf()
        )
      })
    },
    timeZoneOffset: state => {
      return moment.tz(state.timezone).format('Z')
    },
    notify_type_name: state => {
      if (state.userInfo) {
        return {
          leaveBed: i18n.t('__notifyTypeLeaveBed'), // 離床通知
          leaveTimeout: i18n.t('__notifyTypeLeaveBed'), // 離床通知
          siteTimeout: i18n.t('__notifyTypeLeaveBed'), // 離床通知
          lyingTimeout: i18n.t('__notifyTypeLying'), // 臥床通知
          respirationRate: i18n.t('__notifyTypeRespiration'), // 呼吸通知
          respirationRateGreater: i18n.t('__notifyTypeRespiration'), // 呼吸通知
          respirationRateLess: i18n.t('__notifyTypeRespiration'), // 呼吸通知
          restlessRateGreater: i18n.t('__notifyTypeRestless'), // 體動通知
          mqttStatus: i18n.t('__notifyTypeNetwork') // 網路通知
        }
      }
    },
    notify_condition_name: state => {
      if (state.userInfo) {
        return {
          leaveBed: i18n.t('__notifyConditionLeaveBed'), // 離床
          leaveTimeout: i18n.t('__notifyConditionLeaveTimeout'), // 離床時間大於
          lyingTimeout: i18n.t('__notifyConditionLyingTimeout'), // 臥床時間大於
          respirationRateGreater: i18n.t(
            '__notifyConditionRespirationRateGreater'
          ), // 呼吸率大於
          respirationRateLess: i18n.t('__notifyConditionRespirationRateLess'), // 呼吸率小於
          restlessRateGreater: i18n.t('__notifyConditionRestlessRateGreater'), // 體動值大於
          siteTimeout: i18n.t('__notifyConditionSiteTimeout'), // 坐床時間持續
          disconnect: i18n.t('__notifyConditionOffline'), // 離線
          connect: i18n.t('__notifyConditionOnline') // 連線
        }
      }
    },
    notify_unit: state => {
      if (state.userInfo) {
        return {
          leaveBed: '',
          leaveTimeout: i18n.t('__minute'),
          siteTimeout: i18n.t('__second'),
          LyingTimeout: i18n.t('__minute'),
          respirationRateGreater: i18n.t('__times_min'),
          respirationRateLess: i18n.t('__times_min'),
          restlessRateGreater: ''
        }
      }
    }
  },
  actions: {
    getNotification ({ commit }) {
      dashboard
        .getDeviceNotify(this.state.token)
        .then(res => {
          if (res.status <= 201) {
            commit('setNotify_list', res.data.data)
          }
        })
        .catch(error => {
          commit('AlertsInfo', {
            state: 'error',
            title: i18n.t('__error'),
            info: error
          })
          commit('Alerted')
        })
    },
    getUserInfo ({ commit }) {
      setTimeout(() => {
        commit('getToken')
        auth.getUser(this.state.token).then(res => {
          if (res.status <= 201) {
            commit('Timezone', res.data.data.agency.timezone)
            commit('acceptUserInfo', res.data.data)
            i18n.locale =
              res.data.data.agency.locale === 'zh_TW'
                ? 'zh'
                : res.data.data.agency.locale
          }
        })
      }, 0)
    },
    getStatistics ({ commit }) {
      return new Promise((resolve, reject) => {
        var config = Object.assign({}, this.state.token)
        config.params = {}
        config.params.ended_at = new Date().format('yyyy-MM-dd')
        report.getStatistics(config).then(res => {
          if (res.status <= 201 && res.data.status === 'success') {
            commit('Statistics', !Array.isArray(res.data.data))
            resolve()
          }
        })
      })
    }
  },
  mutations: {
    Timezone (state, data) {
      state.timezone = data
    },
    Loading (state) {
      state.isLoading = true
    },
    LoadingText (state, data) {
      state.loadingText = data
    },
    Loaded (state) {
      state.isLoading = false
      state.loadingText = null
    },
    Alerted (state) {
      state.isAlerting = !state.isAlerting
    },
    AlertsInfo (state, data) {
      state.alerts.state = data.state
      state.alerts.icon = data.icon
      state.alerts.title = data.title
      state.alerts.info = data.info
    },
    Statistics (state, data) {
      state.hasStatistics = data
    },
    getViewport (state) {
      const pseudoElementStyleContent = getComputedStyle(
        document.querySelector('body'),
        ':after'
      ).content
      // eslint-disable-next-line no-useless-escape
      state.viewport = pseudoElementStyleContent.replace(/\"/g, '')
    },
    setWindowWidth (state, value) {
      state.windowWidth = value
    },
    setNotify_list (state, data) {
      state.notifyList = data
    },
    acceptUserInfo (state, data) {
      state.userInfo = data
    },
    getToken (state) {
      state.token.headers.Authorization =
        'Bearer ' + localStorage.getItem('humetrics_user_token')
    }
  },
  modules: {}
})
