import { axiosConfig } from '../api/axios-config.js'

export const auth = {
  // 登入
  login: data => axiosConfig.post('/api/login', data),

  // 變更密碼
  changePassword: (password, token) => axiosConfig.post('/api/user', password, token),

  // 取得使用者資訊
  getUser: token => axiosConfig.get('/api/user', token)
}
